import React from 'react';
import { Link } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import ReactGA from 'react-ga';
import placeholder from '../images/placeholder.png'; // Ensure you have a placeholder image in the specified path

const BlogListingCard = ({ post }) => {
  return (
    <div key={post.id} className="blog-listing-card-holder">
      <div className="card main-card-blog-listing mb-3">
        <div className="blog-image">
          {post.acf.blog_grid_image != null ? (
            <Link to={`/study-resources/${post.slug}/`}>
              <img src={post.acf.blog_grid_image.source_url} className="rounded img-fluid blog-listing-card-image" alt={post.title} />
            </Link>
          ) : (
            <Link to={`/study-resources/${post.slug}/`}>
              <img src={placeholder} className="rounded img-fluid blog-listing-card-image" alt={post.title} />
            </Link>
          )}
        </div>
        <div
          onClick={() => {
            ReactGA.event({
              category: "Study Resources",
              action: "Clicks",
              label: `${ReactHtmlParser(post.title)}`,
            });
          }}
          className="card-body px-0 py-3 blog-listing-card-body">
          <div className="category-holder">
            {post.acf.select_category.map((d, index) => (
              <span key={`${d}-${index}`} className="blog-listing-category">
                {d}
              </span>
            ))}
          </div>
          <h5 className="card-title blog-listing-card-title blogtitle">
            <Link to={`/study-resources/${post.slug}/`}>{ReactHtmlParser(post.title)}</Link>
          </h5>
          <p className="card-text blog-listing-card-text blogcontent"> {ReactHtmlParser(post.acf.description)} </p>
          <p className="text-muted blog-listing-date-text">
  - Updated on {post.modified ? post.modified : post.date}
</p>
        </div>
      </div>
    </div>
  );
};

export default BlogListingCard;